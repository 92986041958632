html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* Animations */
@keyframes toastrAnimationBottom {
  from {bottom: -50px;}
  to {bottom: 100px;}
}

.toastr-bottom {
  animation: toastrAnimationBottom .5s forwards;
  position: absolute;
  z-index: 100;
  margin: auto;
  width: fit-content;
  padding: 15px;
  background-color: #444;
  color: #fff;
  left: 0;
  bottom: -50px;
  right: 0;
  border: 0;
  border-radius: 5px;
  opacity: 1;
}

/* Animations */
@keyframes toastrAnimationOpacity {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}

.toastr-opacity {
  bottom: 100px;
  position: absolute;
  z-index: 100;
  margin: auto;
  width: fit-content;
  padding: 15px;
  background-color: #444;
  color: #fff;
  left: 0;
  right: 0;
  border: 0;
  border-radius: 5px;
  opacity: 1;
  animation: toastrAnimationOpacity 2s forwards;
}

.toastr {
  bottom: -50px;
  position: absolute;
  z-index: 100;
  margin: auto;
  width: fit-content;
  padding: 15px;
  background-color: #444;
  color: #fff;
  left: 0;
  right: 0;
  border: 0;
  border-radius: 5px;
  opacity: 1;
  display: none;
}
